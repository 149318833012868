
class searchFrom {
    constructor(type) {
        if (type === 'form') {
            this.loginName = ""
            this.password = ""
            this.passwordAgain = ""
        } else if (type === 'rule') {
            this.loginName = [
                {
                    required: true,
                    message: '用户名',
                    disabled: true,
                    trigger: 'blur',
                },
            ]
            this.password = [
                {
                    required: true,
                    min: 6,
                    max: 16,
                    message: '请输入6到16位新密码',
                    trigger: 'blur',
                },
            ]
            this.passwordAgain = [
                {
                    required: true,
                    min: 6,
                    max: 16,
                    message: '请输入6到16位确认密码',
                    trigger: 'blur',
                },
            ]
        }
    }
}
export default searchFrom
