<template>
  <div class="dialog-container convenienceDialog">
    <el-dialog
      title="商户详情"
      :visible.sync="show"
      @opened="openDialog"
      :before-close="closeDialog"
      top="20px"
      center
      :close-on-click-modal="false"
      width="624px"
    >
      <el-form ref="form" :model="form" label-width="120px">
        <el-row>
          <el-col :span="24">
            <el-form-item label="商户名称" prop="merchantName">
              <span>{{form.merchantName}}</span>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="账号" prop="merchantAccount">
              <span>{{form.merchantAccount}}</span>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="主体信息" prop="entityTypeId">
              <ul class="main-type-ul" :style="{ marginLeft: '10px' }">
                <li class="main-type-ul-li" v-for="(item,index) in form.list" :key="index">
                  <div class="item-title">{{`${item.entityName}：`}}</div>
                  <div class="item-name">{{item.sportCode}}</div>
                </li>
              </ul>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template slot="footer">
        <el-button class="footer-confirm" @click="sureEvent">{{$t('sure')}}</el-button>
        <el-button class="footer-cancel" @click="closeDialog">{{$t('cancel')}}</el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import mixin from "@/mixins/dialogMixin";
import apis from "@/apis";

export default {
  mixins: [mixin],
  components: {
  },
  props: {
    show: {
      required: true,
      type: Boolean,
    },
    Language: {
      required: false,
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      form: {},
      apis,
    };
  },
  activated() {},
  methods: {
    /**
     * @function 关闭弹窗
     * @description 先初始化，然后传值到组件外部进行关闭
     **/
    async closeDialog() {
      await this.init()
      this.$emit('closeDialog', false)
    },
    /**
     * @function 初始化弹窗
     */
    init() {
      this.$refs.form.resetFields();
      this.form = {};
    },
    /**
     * @function 点击确定按钮，先执行业务，完了调关闭方法，不要写两次关闭方法
     */
    sureEvent() {
      this.closeDialog()
    },
    /**
     * @function 回显的时候获取数据
     * @param {Object} data 要传输给后端的数据
     */
    getDataQuery(data) {
      // 在这里获取回显的数据
      this.$http.get(`${apis.findMerchantAccountDetailInfo}?id=${data.id}`).then((res) => {
        if (res.data.code === 0) {
          const entityBaseInfoList = res.data.rows.entityBaseInfoList;
          const list = [];
          if (entityBaseInfoList && entityBaseInfoList.length && entityBaseInfoList.length > 0) {
            entityBaseInfoList.forEach((item) => {
              list.push({
                ...item,
                ...{
                  sportCode: this.arrayToString(item.sportsProjectList),
                },
              })
            })
          }
          console.log("+++++++++entityBaseInfoList:", entityBaseInfoList)
          this.form = {
            ...res.data.rows,
            ...{
              feeRatio: (res.data.rows && res.data.rows.settlementMerchantRateConfigInfo && res.data.rows.settlementMerchantRateConfigInfo.rateTypeCode === '0' ? '零费率' : res.data.rows.settlementMerchantRateConfigInfo && res.data.rows.settlementMerchantRateConfigInfo.fixAmount) || "",
              list,
            },
          };
          console.log("++++++++this.form:", this.form);
        }
      });
    },
    // 数组取字符串
    arrayToString(list = []) {
      let str = "";
      if (list.length > 0) {
        list.forEach((item, index) => {
          if (index === 0) {
            str += item.mainDirectionName
          } else {
            str += `、${item.mainDirectionName}`
          }
        })
      }
      return str
    },
    openDialog() {
      console.log("+++++++openDialog")
    },
  },
};
</script>
<style lang="less" scoped>
.el-form-item {
  margin-bottom: 0;
}
.image-upload {
  width: 50px;
  height: 50px;
}
.upload-images-tip {
  color:rgba(168,168,168,1);
  font-size:12px;
  margin-top: -15px;
}
.bmap-wrap {
  width: 100%;
}
.image-wrap{
  width: 50px;
  height: 50px;
  margin-right: 10px;
  position: relative;
  float: left;
  .images-close {
    position: absolute;
    right: -7px;
    top: -7px;
    width: 14px;
    height: 14px;
    cursor: pointer;
  }
}
.line {
  width: 100%;
  height: 0;
  border: 1px solid #ccc;
  margin-top: 10px;
  margin-bottom: 10px;
}
.main-type-ul li {
  list-style-type: none;
}
.main-type-ul-li {
  display: flex;
  align-items: center;
  .item-title {
    margin-right: 5px;
  }
}
.fee-title {
  font-size: 16px;
  color: #000000;
  font-weight: 700;
  margin-bottom: 10px;
}
</style>
