/* eslint-disable */
class searchFrom {
    constructor(type) {
      if (type === 'form') {
        this.venueId = ''
        this.venueAccount = ''
        this.password = ''
        this.onOffStatus = ''
      } else if (type === 'rule') {
        this.venueId = [{ required: true, message: '请选择场馆名称', trigger: ['change', 'blur'] }]
        this.venueAccount = [{ required: true, message: '请输入账号', trigger: 'blur' }]
        this.password = [{ required: true, message: '请输入密码', trigger: 'blur' }]
        this.onOffStatus = [{ required: false, message: '请选择状态', trigger: ['change', 'blur'] }]
      }
    }
  }
  export default searchFrom
  