<template>
  <div class="accountManage">
    <div class="search-container search-container-new">
      <el-row >
        <el-col :span="16">
          <el-form ref="form" :model="form" label-width="100px">
            <el-row>
              <el-col :span="8">
                <el-form-item prop="loginName" label="场馆账号：">
                  <el-input clearable v-model="form.loginName" placeholder="请输入场馆账号"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item prop="venueName	" label="场馆名称：">
                  <el-input clearable v-model="form.venueName" placeholder="请输入场馆名称"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-col>
        <el-col :span="8" style="text-align:right">
          <el-button @click="cleanForm" class="clean-button">{{$t('reset')}}</el-button>
          <el-button @click="searchEvent" class="search-button">{{$t('search')}}</el-button>
        </el-col>
      </el-row>
    </div>
    <div class="table-container">
      <el-row>
        <el-col :span="8">
          <el-button style="float:left;" class="default-button add-button" @click="changeAddDialog(true)">{{$t('addTo')}}</el-button>
        </el-col>
      </el-row>
      <el-table
				:data="tableData"
        :height="tableHeight"
				style="width: 100%"
        border
        :row-class-name="tableRowClassName">
        <el-table-column
					prop="venueId"
          min-width="168"
          align="center"
					label="场馆ID">
				</el-table-column>
        <el-table-column
					prop="venueName"
          min-width="168"
          align="center"
					label="场馆名称">
				</el-table-column>
        <el-table-column
					prop="loginName"
          min-width="168"
          align="center"
					label="账号">
				</el-table-column>
        <el-table-column
					prop="roleName"
          min-width="168"
          align="center"
					label="角色名称">
				</el-table-column>
        <el-table-column
					prop="status"
          align="center"
          min-width="115"
					label="状态">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.onOffStatus" active-value="0" inactive-value="1" @change="(value) => updateStatus(value, scope.row, scope.$index)"></el-switch>
          </template>
				</el-table-column>
        <el-table-column
					prop="collectCount"
          align="center"
          width="336"
					label="操作">
          <template slot-scope="scope">
            <el-button class="default-button reset-button" v-if="scope.row.onOffStatus==='1'" @click="openConfirmDialog(scope.row)">{{$t('reset_password')}}</el-button>
            <el-button class="default-button" v-if="scope.row.onOffStatus==='1'" @click="openDeleteDialog(scope.row.userId, scope.row.venueId, 1)">{{$t('delete')}}</el-button>
          </template>
				</el-table-column>
			</el-table>
    </div>
    <div class="page-container">
      <el-pagination
				@size-change="handleSizeChange"
				@current-change="handleCurrentChange"
				:current-page="form.pageNum"
				:page-sizes="[10, 20, 30, 40]"
				:page-size="form.pageSize"
				layout="total, sizes, prev, pager, next, jumper"
				:total="form.total">
			</el-pagination>
    </div>
    <!-- 添加/修改弹窗 -->
    <add-dialog ref="addDialog" :Language="Language" :show="addDialog.visible" :isEdit="addDialog.isEdit" :venueList="venueList" @closeDialog="changeAddDialog"></add-dialog>
    <!-- 删除确认弹窗 -->
    <confirm-dialog ref="confirmDialog" :Language="Language" :show="confirmDialog.visible" :type="confirmDialog.type" @sureDialog="sureConfirmDialog" @closeDialog="changeConfirmDialog"></confirm-dialog>
    <!-- 查看详情弹窗 -->
    <detail-dialog ref="detailDialog" :Language="Language" :show="detailDialog.visible" @sureDialog="sureDetailDialog" @closeDialog="changeDetailDialog"></detail-dialog>
    <!-- 修改密码 弹窗 -->
    <password-dialog ref="passwordDialog" :passwordData="passwordDialog.passwordData"  :Language="Language" :show="passwordDialog.visible" :isEdit="passwordDialog.isEdit" @closeDialog="passwordConfirmDialog"></password-dialog>
  </div>
</template>
<script>
import IndexClass from './indexClass'
import mixin from '@/mixins/index'
import apis from '@/apis'
import { mapState } from "vuex";
import addDialog from "./dialog/addDialog.vue";
import confirmDialog from '@/components/confirmDialog.vue'
import detailDialog from './dialog/detailDialog.vue'
import download from '@/mixins/download'
import passwordDialog from './dialog/changePassword.vue'

export default {
  mixins: [mixin, download],
  components: {
    addDialog,
    confirmDialog,
    detailDialog,
    passwordDialog,
  },
  data() {
    return {
      form: new IndexClass('form'), // 实例化一个表单的变量
      tableData: [],
      addDialog: {
        visible: false,
        isEdit: false,
      },
      confirmDialog: {
        visible: false,
        type: 1, //1:删除 9：发布
        data: {},
      },
      detailDialog: {
        visible: false,
        data: {},
      },
      passwordDialog: {
        passwordData: {
          loginName: "",
          password: "",
        },
        visible: false,
        isEdit: false,
      },
      mainTypeBox: [],
      mainStatusBox: [
        {
          label: "关闭",
          value: "0",
        },
        {
          label: "开启",
          value: "1",
        },
      ], // 主体状态下拉框
      mainInfoList: [],
      total: 0,
      venueList: [],
    }
  },
  computed: {
    ...mapState(["Language", "DROPDOWNBOX", "TOKEN", 'venueId', 'merchantAccountDetailInfoDTO']),
  },
  activated() {
    this.getMainType()
    this.getVenueListByMerchantId()
  },
  methods: {
    // tabs切换
    tabsClick() {
      this.searchEvent()
    },
    // 表格选中
    handleSelectionChange(arr) {
      this.selectedArr = arr
    },
    // 清空按钮
    cleanForm() {
      this.$refs.form.resetFields()
      //清空
      this.form.loginName = ""
      this.form.venueName = ""
      this.searchEvent()
    },
    // 查询
    // 获取账户管理列表数据
    searchData() {
      const formData = { ...this.form }
      console.log("+++++++formData:", formData)
      this.$http.post(apis.getVenueAccountList, formData).then((res) => {
        if (res.data.code === 0) {
          this.tableData = res.data.rows
          this.form.total = res.data.total
          this.total = res.data.total
        }
      })
    },
    // 新增修改
    changeAddDialog(dialogStatus, isEdit = false, data) {
      if (dialogStatus) {
        this.addDialog.isEdit = isEdit;
        if (isEdit) {
          // 调用vuex里面的获取数据的业务，如果要在这里写接口，需要改成Promise
          this.$refs["addDialog"].getDataQuery(data);
        }
      } else {
        this.searchData();
      }
      this.$nextTick(() => {
        this.addDialog.visible = dialogStatus;
      });
    },
     /**
     * @function 改变详情弹窗的状态
     * @param {Boolean} dialogStatus 是否展示当前弹窗
     * @param {Object} data 打开详情，传递数据
     */
    changeDetailDialog(dialogStatus, data = {}) {
      this.detailDialog.data = data
      this.detailDialog.visible = dialogStatus
      if (!dialogStatus) {
        this.detailDialog.data.ids = ''
        this.searchEvent()
      } else {
        this.$refs["detailDialog"].getDataQuery(data);
      }
    },
    /**
     * @function 改变确认/删除弹窗的状态
     * @param {Boolean} dialogStatus 是否展示当前弹窗
     * @param {Number} type 打开的弹窗类型，1为删除确认
     */
    changeConfirmDialog(dialogStatus, type = 1) {
      this.confirmDialog.type = type
      this.confirmDialog.visible = dialogStatus
      if (!dialogStatus) {
        this.confirmDialog.data.ids = ''
        this.searchEvent()
      }
    },
    // 查看详情-确认
    sureDetailDialog() {
      console.log("++++++++查看详情")
    },
    /**
     * 删除和发布点击确认
     * id: 对应的id值
     * type 1：删除 9:发布
     */
    /* eslint-disable */
    openDeleteDialog(userId, venueId, type) {
      this.confirmDialog.type = type
      this.confirmDialog.data.userId = userId
      this.confirmDialog.data.venueId = venueId
      this.changeConfirmDialog(true, type)
    },
    /**
     * @function 当确认弹窗点击确定的时候触发
     */
    sureConfirmDialog() {
      // 如果一个页面有多个弹窗，视情况决定用if语句还是二元表达式对api进行判断
      this.modelDelete()
    },
    /**
     * 删除
     */
    modelDelete() {
      const params = {
        userIds: this.confirmDialog.data.userId + '',
        venueIds: this.confirmDialog.data.venueId,
      }
      this.$http.post(apis.delMerchant, params).then((res) => {
        if (res.data.code === 0) {
          this.$refs.confirmDialog.closeDialog()
          this.$message.success("删除成功!")
          this.searchData()
        }
      })
    },
    /**
     * @function 打开确认重置密码弹窗
     * @param {Array} data 要重置密码的值
     */
    openConfirmDialog(data) {
      this.passwordDialog.passwordData = {
        ...this.passwordDialog.passwordData,
        ...{
          userId: data.userId,
          loginName: data.loginName,
          venueId: data.venueId,
        },
      }
      this.$nextTick(() => {
        this.passwordDialog.visible = true;
        this.$refs.passwordDialog.getInfo();
      })
    },
    passwordConfirmDialog() {
      this.passwordDialog.visible = false;
    },
    // 导入
    uploadError(res) {
      console.log(res);
      this.searchEvent()
    },
    uploadSuccess(res) {
      if (res.data.code === 0) {
        this.searchEvent()
      }
    },
    updateStatus(value, data, index) { // 主体状态改变
      const { userId, venueId, reservationSupport } = data
      const params = {
        userId,
        venueId,
        reservationSupport,
        status: value,
      }
      this.$http.post(apis.updateVenueAdmin, params).then((res) => {
        if (res.data.code === 0) {
          console.log(res.data)
          this.searchEvent()
        } else {
          if (value === '0') {
            this.tableData[index].onOffStatus = '1'
          } else {
            this.tableData[index].onOffStatus = '0'
          }
        }
      })
    },
    downFile() {
      const mimeMap = {
        xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=utf-8',
        zip: 'application/zip',
        xml: 'application/xml',
      }
      this.$http.get(apis.downloadVenueImportTemplate, {responseType: 'blob'},).then((res) => {
        this.resolveBlob(res, mimeMap.xlsx)
      })
    },
    // 获取主体类型下拉取值
    getMainType() {
      this.$http.get(apis.getMainType).then((res) => {
        if (res.data.code === 0) {
          this.mainTypeBox = res.data.data
        }
      })
    },
    // 获取主体类型下拉取值
    getVenueListByMerchantId() {
      this.$http.get(`${apis.getVenueListByMerchantId}?merchantId=${this.merchantAccountDetailInfoDTO.merchantId}`).then((res) => {
        if (res.data.code === 0) {
          this.venueList = res.data.data
        }
      })
    },
  },
}
</script>
<style lang="less" scoped>
@import "../../../assets/styles/mixin.scoped.less";
</style>
<style lang="less" scoped>
@import "../../../assets/styles/mixin.less";
.accountManage {
  .search-container {
    padding-top: 16px;
  }
  .page-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
  }
  .el-form-item {
    margin-bottom: 0;
  }
  .default-button {
    border-radius: 2px;
  }
  .add-button {
    margin-bottom: 15px;
  }
  .main-title {
    margin: 10px;
  }
  .num-label {
    margin-right: 10px;
  }
}
</style>
